<template>
  <div style="width: 100%;display: flex;flex-direction: row;align-items: center;justify-content:center">
    오오호호호호 상품 메인 페이지
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import TermsOfUse from "@/views/policy/text/TermsOfUse";
import TermsOfPrivacyPolicy from "@/views/policy/text/TermsOfPrivacyPolicy";
import TermsOfElectronicFinancialTrans from "@/views/policy/text/TermsOfElectronicFinancialTrans";

export default {
    mixins: [validationMixin],
    components:{
      TermsOfUse,
      TermsOfPrivacyPolicy,
      TermsOfElectronicFinancialTrans,
    },

    validations: {
        rules: {
            checked (val) {
                return val
            },
        },
        cash: {
            checked (val) {
                return val
            },
        },
        privacy: {
            checked (val) {
                return val
            },
        }
    },

    data: () => ({
        all:false,
        rules:false,
        cash:false,
        privacy:false,
        submitStatus: null
    }),

    computed: {
        rules_Errors () {
            const errors = []
            if (!this.$v.rules.$dirty) return errors
            !this.$v.rules.checked && errors.push('위셀페이 이용약관에 동의하셔야 가입하실 수 있습니다.')
            return errors
        },
        cash_Errors () {
            const errors = []
            if (!this.$v.cash.$dirty) return errors
            !this.$v.cash.checked && errors.push('전자금융거래 이용약관에 동의하셔야 가입하실 수 있습니다.')
            return errors
        },
        privacy_Errors () {
            const errors = []
            if (!this.$v.privacy.$dirty) return errors
            !this.$v.privacy.checked && errors.push('개인정보수집/이용안내에 동의하셔야 가입하실 수 있습니다.')
            return errors
        }
    },

    methods: {
        check_all(){
            if(this.all){
                this.rules=true;
                this.cash=true;
                this.privacy=true;
            }else{
                this.rules=false;
                this.cash=false;
                this.privacy=false;
            }
        },
        submit () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                this.submitStatus = 'PENDING'
                setTimeout(() => {
                this.submitStatus = 'OK'
                }, 500)
                this.$router.push('/join/form')
            }
        }
    }
}
</script>
<style>
.rule_box{
    min-width:600px;
    height: 300px;
    overflow: auto;
    border: 1px solid #e9e9e9;
    text-align:left;
    padding:5px;
    background:#f9f9f9;
}

.rule_box h2{
    color:#a87675;
    margin-bottom:10px;
}


.rule_box h3{
    color:#333;
}

.rule_mobile_box{
    height: 200px;
    overflow: auto;
    border: 1px solid #e9e9e9;
    text-align:left;
    padding:5px;
    margin: 0 20px;
    background:#f9f9f9;
}

.rule_mobile_box h2{
    color:#a87675;
    margin-bottom:10px;
}


.rule_mobile_box h3{
    color:#333;
}
</style>