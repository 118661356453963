<template>
    <div id="seller-error-page" style="margin-top: 150px">
      <v-card width="500" style="margin-top:120px; margin-bottom:200px;margin: 0 auto"
              class="pt-10 pb-10 mx-auto">
        <div style="margin: 0 auto;width: 225px;padding-top: 50px">
          <img width="225" :src="require('@/assets/login/logo.png')"
          >
        </div>
          <div style="font-weight: bold;font-size: large;text-align: center;padding: 50px 50px;">
            죄송합니다.<br/>
            요청하신 페이지를 찾을 수 없습니다.<br/>
          </div>
          <v-sheet>
            <v-btn @click="goHome()" tile large dark depressed color="#02BDBB" block class="mt-4"
                   style="margin-top: 20px">
              홈으로 이동
            </v-btn>
          </v-sheet>
      </v-card>
    </div>
</template>

<script>
    export default {
        name: "ErrorPage",
        beforeRouteEnter (to, from, next) {
            // called before the route that renders this component is confirmed.
            // does NOT have access to `this` component instance,
            // because it has not been created yet when this guard is called!
            console.log('ErrorPage , beforeRouteEnter')
            //this.$store.commit('ERR_PAGE',true)
            next()
        },
        data() {
            return {
            }
        },
        methods:{
            goHome(){
              window.location.href=process.env.VUE_APP_WEBMAIN_HOST
            },
        }
    }
</script>

<style scoped>

    #seller-error-page{
      display: block;
      position: relative;
      margin: 0 auto;
    }
    .error-page{
        position: relative;
        display: block;
        top: 150px;
        width: 480px;
        margin: 0 auto;
      text-align: center;
    }
</style>