<template>
  <router-view class="noto" />
</template>
<script>
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const {ChannelTalk} = require("@/lib/ChannelTalk");
gsap.registerPlugin(ScrollTrigger);



export default {
  name: "App",
  async created() {
    ChannelTalk.loadChannelTalk('56e7c27d-4c5e-441a-ac64-cd940c3088e4'
            ,'chcplee@weselpay.com',{
              "name": "USER_NAME", // fill user's name
              "mobileNumber": "USER_MOBILE_NUMBER", // fill user's mobile number
              "CUSTOM_VALUE_1": "VALUE_1", // custom property
              "CUSTOM_VALUE_2": "VALUE_2" // custom property
            }
    )
  }
};
</script>
<style lang="scss">
/*@font-face {
  font-family: "Noto Sans CJK KR";
  src: url(./assets/fonts/NotoSansCJKkr-Thin.otf) format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "Noto Sans CJK KR";
  src: url(./assets/fonts/NotoSansCJKkr-DemiLight.otf) format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "Noto Sans CJK KR";
  src: url(./assets/fonts/NotoSansCJKkr-Light.otf) format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Noto Sans CJK KR";
  src: url(./assets/fonts/NotoSansCJKkr-Regular.otf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Noto Sans CJK KR";
  src: url(./assets/fonts/NotoSansCJKkr-Medium.otf) format("truetype");
  font-weight: 500;
}*/
/* @font-face {
    font-family: "Noto Sans CJK KR";
    src: url(./assets/fonts/NotoSansCJKkr-Bold.otf) format("truetype");
    font-weight:700;
}
@font-face {
    font-family: "Noto Sans CJK KR";
    src: url(./assets/fonts/NotoSansCJKkr-Black.otf) format("truetype");
    font-weight:900;
} */

// .noto p {
//   font-family: "Noto Sans CJK KR";
// }

// .noto font {
//   font-family: "Noto Sans CJK KR";
// }

@font-face {
  font-family: "NEXON Lv1 Gothic OTF";
  src: url("./assets/fonts/NEXON Lv1 Gothic OTF Light.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "NEXON Lv1 Gothic OTF";
  src: url("./assets/fonts/NEXON Lv1 Gothic OTF.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "NEXON Lv1 Gothic OTF";
  src: url("./assets/fonts/NEXON Lv1 Gothic OTF Bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "NEXON Lv1 Gothic OTF";
  src: url("./assets/fonts/NEXON Lv1 Gothic OTF Bold.woff") format("woff");
  font-weight: 900;
}

@font-face {
  font-family: "Spoqa Han Sans Neo";
  src: url("./assets/fonts/SpoqaHanSansNeo-Light.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Spoqa Han Sans Neo";
  src: url("./assets/fonts/SpoqaHanSansNeo-Medium.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Spoqa Han Sans Neo";
  src: url("./assets/fonts/SpoqaHanSansNeo-Bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Spoqa Han Sans Neo";
  src: url("./assets/fonts/SpoqaHanSansNeo-Bold.woff") format("woff");
  font-weight: 900;
}

html,
body {
  font-family: "NEXON Lv1 Gothic OTF", sans-serif;
  color: #000;
  background-color: #fff;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}

.noto .renew,
.noto .renew p,
.noto .renew font {
  font-family: "NEXON Lv1 Gothic OTF";
}

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
}

.v-application--wrap {
  overflow: hidden;
}

.v-application .renew strong {
  font-weight: 700;
}

.renew {
  h1,
  h2,
  h3,
  h4,
  a,
  p,
  span,
  strong,
  ul,
  ol,
  li,
  div,
  section,
  main,
  nav,
  header,
  footer,
  img,
  video,
  form,
  label,
  button {
    margin: 0;
    padding: 0;
    border: 0;
  }

  ul,
  ol {
    list-style: none;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  footer,
  header,
  nav,
  section {
    display: block;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  :focus:not(:focus-visible) {
    outline: none;
  }

  .max-w-1200-wrap {
    padding-left: 56px;
    padding-right: 56px;
    width: 100%;
  }

  .max-w-1200 {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .hidden-lg {
    display: none;
  }

  @media screen and (max-width: 1312px) {
    .max-w-1200-wrap {
      padding-left: 4.2683vw;
      padding-right: 4.2683vw;
      width: 100%;
    }
  }

  @media screen and (max-width: 650px) {
    .max-w-1200-wrap {
      padding-left: 4.2667vw;
      padding-right: 4.2667vw;
    }
    .hidden-lg {
      display: block;
    }
    .hidden-sm {
      display: none;
    }
  }
}
</style>