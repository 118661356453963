import Vue from 'vue'
import VueRouter from 'vue-router'
import ErrorPage from "@/components/ErrorPage";
import {SessionToken} from "@/lib/SessionManager";
import {AuthManager} from "@/lib/AuthManager";
import ProductMenu from "../views/guide/product/ProductMenu.vue";
import ProductListContent from "../views/guide/product/ProductListContent.vue";
import ProductRegisterContent from "../views/guide/product/ProductRegisterContent.vue";
import CategoryContent from "../views/guide/product/CategoryContent.vue";
import OrderMenu from "../views/guide/order/OrderMenu.vue";
import OrderAllListContent from "../views/guide/order/OrderAllListContent.vue";
import ProductQnAContent from "../views/guide/product/ProductQnAContent.vue";
import PurchaseReviewContent from "../views/guide/product/PurchaseReviewContent.vue";
import DeliveryGroupContent from "../views/guide/product/DeliveryGroupContent.vue";
import OrderWaitDepositListContent from "../views/guide/order/OrderWaitDepositListContent.vue";
import GuideMenu from "../views/guide/GuideMenu.vue";
import GuideIndexContent from "../views/guide/GuideMenuContent.vue";

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name:'home',
    component: () => import(/* webpackChunkName: "home" */ '@/layouts/default/index.vue'),
    children: [
      {
        path: '/',
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/Home.vue')
      },
      {
        path: '/event',
        component: () => import(/* webpackChunkName: "home" */ '@/views/event/Event.vue')
      },
      {
        path: 'main',
        component: () => import(/* webpackChunkName: "page" */ '@/layouts/empty.vue'),
        children: [
          {
            path: "introduce/product",
            alias: "introduce",
            component: () =>
              import(
                /* webpackChunkName: "page" */ "@/views/page/introduce/introduce.vue"
              ),
          },
          {
            path: "introduce/order",
            component: () =>
              import(
                /* webpackChunkName: "page" */ "@/views/page/introduce/introduce_order.vue"
              ),
          },
          {
            path: "introduce/message",
            component: () =>
              import(
                /* webpackChunkName: "page" */ "@/views/page/introduce/introduce_message.vue"
              ),
          },
          {
            path: "introduce/member",
            component: () =>
              import(
                /* webpackChunkName: "page" */ "@/views/page/introduce/introduce_member.vue"
              ),
          },
          {
            path: "introduce/shop",
            component: () =>
              import(
                /* webpackChunkName: "page" */ "@/views/page/introduce/introduce_shop.vue"
              ),
          },
          {
            path: 'cost',
            component: () => import(/* webpackChunkName: "page" */ '@/views/page/cost.vue')
          },
          {
            path: 'guide',
            component: () => import(/* webpackChunkName: "page" */ '@/views/page/guide.vue')
          },
          {
            path: 'cs',
            name:'cs',
            component: () => import(/* webpackChunkName: "page" */ '@/views/page/cs.vue')
          },
          {
            path: 'qa/post',
            component: () => import(/* webpackChunkName: "page" */ '@/views/page/qa_post.vue')
          },
          {
            path: 'qa/read',
            name:'qa-read',
            component: () => import(/* webpackChunkName: "page" */ '@/views/page/qa_read.vue')
          },
          {
            path: 'qa/modify',
            name:'qa-modify',
            component: () => import(/* webpackChunkName: "page" */ '@/views/page/qa_modify.vue')
          },
          {
            path: 'cs/read',
            component: () => import(/* webpackChunkName: "page" */ '@/views/page/cs_read.vue')
          },
        ]
      },
      {
        path: 'auth',
        component: () => import(/* webpackChunkName: "auth" */ '@/layouts/empty.vue'),
        children: [
          {
            path: 'login',
            component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/login.vue')
          },
          {
            path: 'logout',
            component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/logout.vue')
          },
          {
            path: 'find/id',
            component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/find/id.vue')
          },
          {
            path: 'find/password',
            component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/find/password.vue')
          },
          {
            path: 'admin/login',
            component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/AdminLogin.vue')
          },
        ]
      },
      {
        path: 'join',
        component: () => import(/* webpackChunkName: "join" */ '@/layouts/empty.vue'),
        children: [
          {
            path: 'agree',
            component: () => import(/* webpackChunkName: "join" */ '@/views/join/agree.vue')
          },
          {
            path: 'form',
            component: () => import(/* webpackChunkName: "join" */ '@/views/join/form.vue')
          },
          {
            path: 'welcome',
            component: () => import(/* webpackChunkName: "join" */ '@/views/join/welcome.vue')
          },
        ]
      },
      {
        path: 'policy',
        component: () => import(/* webpackChunkName: "policy" */ '@/layouts/empty.vue'),
        children: [
          {
            path: 'rules',
            component: () => import(/* webpackChunkName: "policy" */ '@/views/policy/rules.vue')
          },
          {
            path: 'privacy',
            component: () => import(/* webpackChunkName: "policy" */ '@/views/policy/privacy.vue')
          },
          {
            path: 'electTransPolicy',
            component: () => import(/* webpackChunkName: "policy" */ '@/views/policy/ElectronicFinancialTransPolicy.vue')
          }
        ]
      },
    ]
  },
  {
    path: '/help/guide',
    component: () => import('@/layouts/guide/GuideContainer.vue'),
    children: [
      {
        path: 'home',
        name:'index-home',
        meta:{
          topMenuId:0
        },
        components: {
          menu : GuideMenu, content: GuideIndexContent
        },
      },
      {
        path: 'product/list',
        name:'product-list',
        meta:{
          topMenuId:1
        },
        components: {
          menu : ProductMenu, content: ProductListContent
        },
      },
      {
        path: 'product/register',
        name:'product-register',
        meta:{
          topMenuId:1
        },
        components: {
          menu : ProductMenu, content: ProductRegisterContent
        },
      },
      {
        path: 'product/category',
        name:'category',
        meta:{
          topMenuId:1
        },
        components: {
          menu : ProductMenu, content: CategoryContent
        },
      },
      {
        path: 'product/review',
        name:'purchase-review',
        components: {
          menu : ProductMenu, content: PurchaseReviewContent
        },
      },
      {
        path: 'product/qna',
        name:'product-qna',
        components: {
          menu : ProductMenu, content: ProductQnAContent
        },
      },
      {
        path: 'product/delivery-group',
        name:'product-delivery-group',
        components: {
          menu : ProductMenu, content: DeliveryGroupContent
        },
      },
      {
        path: 'order/all',
        name:'order-all',
        meta:{
          topMenuId:2
        },
        components: {
          menu : OrderMenu, content: OrderAllListContent
        },
      },
      {
        path: 'order/wait-deposit',
        name:'order-wait-deposit',
        meta:{
          topMenuId:2
        },
        components: {
          menu : OrderMenu, content: OrderWaitDepositListContent
        },
      },
    ]
  },
  {path: '*' , component: ErrorPage},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
})


router.beforeEach(async (to,from,next) => {
  const ret = await AuthManager.validateAndRedirectLoginPage()
  if( ret  == false){
    console.log("로그인 페이지로 이동")
    SessionToken.clear()
  }
  ret ? next() : next('/auth/login')
})


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err;
  });
};


export default router
