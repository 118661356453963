<template>
  <div style="width: 100%;display: flex;flex-direction: row;align-items: center;justify-content:center">
    오오호호호호 주문 메인 페이지222222
  </div>
</template>
<script>

export default {

    components:{

    },
    data: () => ({
        all:false,
        rules:false,
        cash:false,
        privacy:false,
        submitStatus: null
    }),

    computed: {

    },

    methods: {

    }
}
</script>
<style scoped>
</style>