<template>
  <div class="w-100 pb-10">
    <div style="border-bottom: 1px solid black" class="text-center py-8 w-100">
      <span class="text-h4">카테고리</span>
    </div>
    <div class="mt-10 w-100">
      <div class="w-100 text-center">
        <span class="pl-15 text-h5 font-weight-bold">상위 카테고리 등록</span>
      </div>
      <div class="text-center w-100 mt-5">
        <img src="../../../assets/guide/product/category_1.png" style="width: 900px" />
        <div style="width: 900px" class="mx-auto text-left pt-4">
          <p>
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">1 최상위 카테고리 추가</span><br>
            버튼 클릭시 상품 카테고리 등록 다이얼로그가 생성됩니다. 최상위 카테고리 정보를 입력하여 새 카테고리를 생성합니다.
          </p>
          <p class="mt-1">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">2 저장/취소</span><br>
            새로 등록된 카테고리는 저장 버튼을 눌러 반드시 저장을 완료해야 합니다.<br>
            취소시 등록되거나 변경된 모든 정보가 초기화 됩니다.
          </p>
        </div>
        <img src="../../../assets/guide/product/category_2.png"/>
        <div style="width: 900px" class="mx-auto text-left pt-4">
          <p>
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">1 카테고리 이름</span><br>
            카테고리 이름을 입력합니다.
          </p>
          <p class="mt-1">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">2 카테고리 노출</span><br>
            {{categoryExposeMsg}}
          </p>
        </div>
      </div>
    </div>
    <div class="mt-10 w-100">
      <div class="w-100 text-center">
        <span class="pl-15 text-h5 font-weight-bold">하위 카테고리 등록</span>
      </div>
      <div class="w-100 text-center mt-5">
        <div style="width: 900px" class="mx-auto text-left pt-4">
          <p class="text-subtitle-1">
            카테고리에서 추가 를 클릭 후 상품 카테고리 등록하면 등록된 카테고리는 선택된 카테고리의 하위에 배치됩니다.<br>
            - 예시 <br>
            의류 카테고리 하위에 여성 의류를 추가한다면, 카테고리 목록에서 의류 카테고리의 추가 버튼을 클릭해서 카테고리 이름에 '여성 의류'를 입력하고 저장합니다.
          </p>
        </div>
      </div>
      <div class="text-center w-100 mt-5">
        <img src="../../../assets/guide/product/category_3.png" style="width: 900px" />
        <div style="width: 900px" class="mx-auto text-left pt-4">
          <p>
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">
              1 추가 변경 삭제
            </span><br>
            추가 : 선택된 카테고리의 하위 카테고리를 추가합니다.<br>
            변경 : 선택된 카테고리의 정보를 변경합니다.<br>
            삭제 : 선택된 카테고리를 삭제합니다. 삭제시 카테고리에 속한 모든 상품들에서 해당 카테고리는 제거 됩니다.
          </p>
        </div>
        <img src="../../../assets/guide/product/category_4.png" class="mt-5"/>
        <div style="width: 900px" class="mx-auto text-left pt-4">
          <p>
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">1 카테고리 이름</span><br>
            카테고리 이름을 입력합니다.
          </p>
          <p class="mt-1">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">2 상위 카테고리</span><br>
             상위 카테고리는 변경할 수 없습니다.
          </p>
          <p class="mt-1">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">3 카테고리 노출</span><br>
            {{categoryExposeMsg}}
          </p>
        </div>
      </div>
    </div>
    <div class="mt-10 w-100">
      <div class="w-100 text-center mt-5">
        <span class="pl-15 text-h5 font-weight-bold">카테고리 이동</span>
      </div>
      <div class="w-100 text-center mt-5">
        <div style="width: 900px" class="mx-auto text-left pt-4">
          <p class="text-subtitle-1">
            등록된 카테고리를 드래그하여 원하는 하위 카테고리로 이동시킬 수 있습니다. 위치 이동 후 반드시 저장을 해야 반영이 완료 됩니다.<br>
            - 예시 : 남성 의류 카테고리를 의류 카테고리의 하위로 변경할 때<br>
            1 남성 의류 카테고리를 선택하고 왼쪽 마우스 버튼을 누른 상태를 유지 합니다.<br>
            2 의류 카테고리로 마우스 포인트를 이동합니다.<br>
            3 잘 조정하여 의류 카테고리의 하위로 위치시키고 왼쪽 마우스 버튼을 해제합니다.<br>
            4 저장 버튼을 눌러서 변경 내역을 저장합니다.
          </p>
        </div>
      </div>
      <div class="text-center w-100 mt-5">
        <img src="../../../assets/guide/product/category_1.gif" style="width: 900px" />
      </div>
    </div>
    <div class="mt-10 w-100">
      <div class="w-100 text-center mt-5">
        <span class="pl-15 text-h5 font-weight-bold">카테고리에 상품 추가</span>
      </div>
      <div class="text-center w-100 mt-5">
        <img src="../../../assets/guide/product/category_5.png" style="width: 900px" />
        <div style="width: 900px" class="mx-auto text-left pt-4">
          <p>
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">1 상품 개수</span><br>
            해당 카테고리에 속한 상품 총 개수입니다. 숫자를 클릭하면 카테고리 상품 관리 화면을 띄웁니다.
            카테고리 상품 관리 다이얼로그에서 카테고리에 상품 등록 및 제외가 가능 합니다.
          </p>
        </div>
        <img src="../../../assets/guide/product/category_6.png"/>
        <img src="../../../assets/guide/product/category_7.png"/>
        <div style="width: 900px" class="mx-auto text-left pt-4">
          <p>
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">1 상품 검색</span><br>
            상품 번호 및 이름으로 상품을 검색합니다. 미선택시 모두 표시 합니다.
          </p>
          <p class="mt-1">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">2 설정 중인 카테고리 이름</span><br>
            현재 선택된 카테고리명 입니다.
          </p>
          <p class="mt-1">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">3 카테고리 상품 조회 설정</span><br>
            카테고리 상품 설정 : 카테고리에 속한 모든 상품들을 표시합니다.<br>
            미등록 상품 설정 : 카테고리에 속하지 않은 모든 상품들을 표시합니다.
          </p>
          <p class="mt-1">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">4 조회된 상품목록</span><br>
            3번에서의 선택된 조건에 따라 모든 상품들의 목록을 표시합니다.
          </p>
          <p class="mt-1">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">5 상품 제외하기</span><br>
            3번에서의 카테고리 상품 설정 선택시 표시됩니다. 선택된 상품들을 카테고리에서 제외 합니다.
          </p>
          <p class="mt-1">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">6 상품 추가하기</span><br>
            3번에서의 미등록 상품 설정 선택시 표시됩니다. 선택된 상품들을 카테고리에 추가 합니다.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    categoryExposeMsg :'노출 체크박스 체크시 카테고리에 속한 모든 상품들은 쇼핑몰에 전시가 됩니다. 미체크시 각 개별 상품들이 전시가 돼 있더라도 쇼핑몰에 전시되지 않습니다.'
  }),
}
</script>
<style scoped>

</style>