<template>
  <div style="width: 100%;" class="px-4 pt-8">
    <div>
      <span class="text-h5">상품관리</span>
    </div>
    <ul style="width: 100%;" class="mt-8">
      <li class="menu-list"
          v-bind:class="{ 'menu-active' : activeMenu('product-list')}"
          v-on:click="onSelected('product-list')">
        <span class="text-subtitle-1 menu-item"
              v-bind:class="{ 'menu-active' : activeMenu('product-list')}"
        >상품목록</span>
      </li>
      <li class="menu-list" v-on:click="onSelected('product-register')"
          v-bind:class="{ 'menu-active' : activeMenu('product-register')}"
      >
        <span class="menu-item text-subtitle-1" v-bind:class="{ 'menu-active' : activeMenu('product-register')}">상품등록</span>
      </li>
      <li class="menu-list"
          v-bind:class="{ 'menu-active' : activeMenu('category')}"
          v-on:click="onSelected('category')"
      >
        <span class="menu-item text-subtitle-1">카테고리</span>
      </li>
      <li class="menu-list"
          v-bind:class="{ 'menu-active' : activeMenu('purchase-review')}"
          v-on:click="onSelected('purchase-review')"
      >
        <span class="menu-item text-subtitle-1" v-bind:class="{ 'menu-active' : activeMenu('purchase-review')}">구매후기</span>
      </li>
      <li class="menu-list"
          v-bind:class="{ 'menu-active' : activeMenu('product-qna')}"
          v-on:click="onSelected('product-qna')"
      >
        <span class="menu-item text-subtitle-1" v-bind:class="{ 'menu-active' : activeMenu('product-qna')}">상품문의</span>
      </li>
      <li class="menu-list"
          v-bind:class="{ 'menu-active' : activeMenu('product-delivery-group')}"
          v-on:click="onSelected('product-delivery-group')"
      >
        <span class="menu-item text-subtitle-1" v-bind:class="{ 'menu-active' : activeMenu('product-delivery-group')}">배송그룹</span>
      </li>
    </ul>
  </div>
</template>
<script>

export default {
  components:{

  },
  created() {
    if(this.$route.meta.topMenuId != 0){
      this.$store.commit('setGuideMenuId','product-list')
    }
  },
  data: () => ({
  }),

  computed: {
    guideMenuId(){
      return this.$store.getters.getGuideMenuId
    }
  },

  methods: {
    activeMenu(menuId){
      return this.guideMenuId === menuId
    },
    onSelected(menuId){
      this.$store.commit('setGuideMenuId',menuId)
      this.$router.push( {name: menuId})
          .catch(()=>{
            this.$router.go(this.$router.currentRoute)
          })
    }
  }
}
</script>
<style scoped>

</style>