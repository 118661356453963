import axios from 'axios'
import {SessionToken} from "@/lib/SessionManager";
import {EventBus} from "@/lib/CommUtil";
import {exceptionHandler} from "@/api/CoreApi";


const DOMAIN = process.env.VUE_APP_API_HOST
const AUTHENTICATION = '/auth/api/v1/auth/accounts/member/authenticate'
const LOGOUT_URL = '/auth/api/v1/auth/accounts/member/logout'
const REFRESH_URL = '/auth/api/v1/auth/token/refresh'
const VALIDATE_URL = '/auth/api/v1/auth/token/validate'



const RefreshResCode= {
    REFRESH_AUTH_FAIL: "01",
    REFRESH_TOKEN_EXPIRED: "02",
    REFRESH_USER_LOGOUT: "03",
    REFRESH_INVALID_TOKEN_KEY: "04",
    REFRESH_USER_INFO_NOT_MATCH: "05",
    REFRESH_RESULT_OK: "00"
}

const authDomain = axios.create({
    baseURL: DOMAIN,
    headers: {
        post: {        // can be common or any other method
            "Content-Type": "application/json;charset=UTF-8"
        },
        'Accept': 'application/json;charset=UTF-8'
    }
})


authDomain.interceptors.response.use((response) => {
    return response;
}, function (error) {
    return Promise.reject(error.response);
});


axios.interceptors.response.use( function ( response) {
    return response;
} ,exceptionHandler)


const request = ( method, url , data) => {
    return axios({
        method, url: DOMAIN + url, data
    }).then( result => result.data)
        .catch( result => {
            throw result.response
        })

}


const setToken = () => {
    let token = SessionToken.getToken()
    if(token != ''){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    }else{
        let exception =new Exception(CommResCode.SESSION_EXPIRED.code,CommResCode.SESSION_EXPIRED.msg);
        throw exception
    }

}

export const AuthApi = {
    login(id, password){
        return request('post',AUTHENTICATION ,{id,password})
    },
    loginAdmin(sellerId , id, password){
        return request('post','/auth/api/v1/auth/accounts/members/'+ sellerId + '/authenticate' ,{id,password})
    },
    autoLogin(id, password){
        return request('post','/auth/api/v2/auth/accounts/member/authenticate' ,{id,password})
    },
    autoSecretLogin(id, password){
        return request('post','/auth/api/v2/auth/accounts/member/auto-authenticate' ,{id,password})
    },
    logout(){
        setToken()
        return request('post',LOGOUT_URL)
    },
    validateToken(token){
        return authDomain.post(VALIDATE_URL,token).then( result => {
            const data = result.data
            console.log(data)

            if( data.resCode === '0000'){
                console.log('요청 성공')
            }else{
                throw data
            }
            return data
        }).catch( err =>{
            console.log(err)
            let response ={
                code: RefreshResCode.REFRESH_INVALID_TOKEN_KEY
            }
            return response
        })
    },
    refresh(){
        let refreshTokenRequest ={
            token : SessionToken.getToken(),
            refreshToken: SessionToken.getRefreshToken()
        }
        return request('put',REFRESH_URL,refreshTokenRequest)
            .then( data => {
                console.log(data)

                let response ={
                    code: '99'
                }

                if( data.resCode === '0000'){
                    console.log('요청 성공')
                    const res = data.data
                    if( res.code === RefreshResCode.REFRESH_RESULT_OK){
                        console.log('refresh 요청 성공')
                        let obj ={
                            token: res.token,
                            refresh: res.refreshToken
                        }
                        SessionToken.setSession(obj)
                        response.code=RefreshResCode.REFRESH_RESULT_OK
                        return response
                    } else {
                        console.log(res)
                        response.code=res.code;
                        return response
                    }

                }
                response.code = RefreshResCode.REFRESH_AUTH_FAIL;
                return response
                //return Promise.resolve(response)
            }).catch( err => {
                console.log(err)
                let response ={
                    code: RefreshResCode.REFRESH_AUTH_FAIL
                }
                return response
            })
    },
}

const cypherRequest = async ( method, url , data) => {
    const res = await axios({
        method, url: DOMAIN + url, data
    })
    return res.data
}

export const AuthCypherApi = {
    async getKey(){
        return await cypherRequest('get','/auth/api/v1/auth/cipher/pkey')
    },
    login(id, password){
        return request('post','/auth/api/v1/auth/accounts/member/enc-authenticate' ,{id,password})
    },
    autoLogin(id, password){
        return request('post','/auth/api/v2/auth/accounts/member/enc-authenticate' ,{id,password})
    },
    autoEncSecretLogin(id, password){
        return request('post','/auth/api/v2/auth/accounts/member/auto-enc-authenticate' ,{id,password})
    },
}



export const logoutApi = {
    doLogout(){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + sessionTokenApi.getToken()
        return request('post',LOGOUT_URL)
    }
}



const mainDomain = axios.create({
    baseURL: process.env.VUE_APP_API_HOST , //'http://localhost:9050',
    headers: {
        post: {        // can be common or any other method
            "Content-Type": "application/json;charset=UTF-8"
        },
        'Accept': 'application/json;charset=UTF-8'
    }
})
