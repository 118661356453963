<template>
  <div class="w-100 pb-10">
    <div style="border-bottom: 1px solid black" class="text-center py-8 w-100">
      <span class="text-h4">상품 등록</span>
    </div>
    <div class="mt-10 w-100">
      <div class="w-100 text-center">
        <span class="pl-15 text-h5 font-weight-bold">전시 기본정보</span>
      </div>
      <div class="text-center w-100 mt-5">
        <img src="../../../assets/guide/product/product__reg_1.png" style="width: 900px" />
        <div style="width: 900px" class="mx-auto text-left pt-4">
          <p>
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">1 카테고리 선택</span><br>
            추가할 카테고리들의 체크박스에 체크를 하여 선택합니다. 하나 이상 선택이 가능합니다.
          </p>
          <img src="../../../assets/guide/product/product__reg_2.png" style="width: 900px" />
          <p>
            선택된 카테고리 목록은 하기와 같이 표시 되며 노출 여부를 설정할 수 있습니다.<br>
            미체크시 카테고리에 속해 있으나 쇼핑몰에서 해당 카테고리 목록에서 보이지 않습니다.
          </p>
          <img src="../../../assets/guide/product/product__reg_3.png" style="width: 900px" />
          <p class="mt-2">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">2 상품 전시 여부</span><br>
            전시 : 상품이 쇼핑몰에 노출이 됩니다.<br>
            미전시 : 상품이 쇼핑몰에 노출이 되지 않습니다.
          </p>
          <p>
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">3 판매 기간 설정</span><br>
            무기간 판매 : 기간 설정 없이 상품 판매를 합니다.<br>
            판매기간 설정 : 상품 판매의 기간을 설정하여 기간외 시간에는 판매를 할 수 없도록 합니다.
          </p>
          <img src="../../../assets/guide/product/product__reg_4.png" style="width: 600px" />
          <p>
            판매시작일 : 상품 판매 시작시간을 설정합니다.<br>
            판매종료일 : 상품 판매 종료시간을 설정합니다.
          </p>
          <img src="../../../assets/guide/product/product__reg_6.png" style="width: 300px" />
          <p>
            쇼핑몰에서 상품 정보에 표시가 됩니다.
          </p>
          <p>
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">4 판매 상태 설정</span><br>
            판매 상태를 설정합니다.
          </p>
          <img src="../../../assets/guide/product/product__reg_5.png" style="width: 300px" />
        </div>
      </div>
    </div>
    <div class="mt-10 w-100">
      <div class="w-100 text-center">
        <span class="pl-15 text-h5 font-weight-bold">상품가격</span>
      </div>
      <div class="w-100 text-center mt-5">
        <div style="width: 900px" class="mx-auto text-left pt-4">
          <p class="text-subtitle-1">
            카테고리에서 추가 를 클릭 후 상품 카테고리 등록하면 등록된 카테고리는 선택된 카테고리의 하위에 배치됩니다.<br>
            - 예시 <br>
            의류 카테고리 하위에 여성 의류를 추가한다면, 카테고리 목록에서 의류 카테고리의 추가 버튼을 클릭해서 카테고리 이름에 '여성 의류'를 입력하고 저장합니다.
          </p>
        </div>
      </div>
      <div class="text-center w-100 mt-5">
        <img src="../../../assets/guide/product/product__reg_7.png" style="width: 900px" />
        <div style="width: 900px" class="mx-auto text-left pt-4">
          <p>
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">1 판매가</span><br>
            실제 판매 가격을 입력합니다. 이 값은 반드시 입력해야 하는 값 입니다.
          </p>
          <p class="mt-1">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">2 이전 판매가</span><br>
            이전 판매가 입니다.
          </p>
          <p class="mt-1">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">3 공급가</span><br>

          </p>
        </div>
        <img src="../../../assets/guide/product/category_4.png" class="mt-5"/>
      </div>
    </div>
    <div class="mt-10 w-100">
      <div class="w-100 text-center mt-5">
        <span class="pl-15 text-h5 font-weight-bold">카테고리 이동</span>
      </div>
      <div class="w-100 text-center mt-5">
        <div style="width: 900px" class="mx-auto text-left pt-4">
          <p class="text-subtitle-1">
            등록된 카테고리를 드래그하여 원하는 하위 카테고리로 이동시킬 수 있습니다. 위치 이동 후 반드시 저장을 해야 반영이 완료 됩니다.<br>
            - 예시 : 남성 의류 카테고리를 의류 카테고리의 하위로 변경할 때<br>
            1 남성 의류 카테고리를 선택하고 왼쪽 마우스 버튼을 누른 상태를 유지 합니다.<br>
            2 의류 카테고리로 마우스 포인트를 이동합니다.<br>
            3 잘 조정하여 의류 카테고리의 하위로 위치시키고 왼쪽 마우스 버튼을 해제합니다.
          </p>
        </div>
      </div>
      <div class="text-center w-100 mt-5">
        <img src="../../../assets/guide/product/category_1.gif" style="width: 900px" />
      </div>
    </div>
    <div class="mt-10 w-100">
      <div class="w-100 text-center mt-5">
        <span class="pl-15 text-h5 font-weight-bold">카테고리에 상품 추가</span>
      </div>
      <div class="text-center w-100 mt-5">
        <img src="../../../assets/guide/product/category_5.png" style="width: 900px" />
        <div style="width: 900px" class="mx-auto text-left pt-4">
          <p>
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">1 상품 개수</span><br>
            해당 카테고리에 속한 상품 총 개수입니다. 숫자를 클릭하면 카테고리 상품 관리 화면을 띄웁니다.
            카테고리 상품 관리 다이얼로그에서 카테고리에 상품 등록 및 제외가 가능 합니다.
          </p>
        </div>
        <img src="../../../assets/guide/product/category_6.png"/>
        <img src="../../../assets/guide/product/category_7.png"/>
        <div style="width: 900px" class="mx-auto text-left pt-4">
          <p>
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">1 상품 검색</span><br>
            상품 번호 및 이름으로 상품을 검색합니다. 미선택시 모두 표시 합니다.
          </p>
          <p class="mt-1">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">2 설정 중인 카테고리 이름</span><br>
            현재 선택된 카테고리명 입니다.
          </p>
          <p class="mt-1">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">3 카테고리 상품 조회 설정</span><br>
            카테고리 상품 설정 : 카테고리에 속한 모든 상품들을 표시합니다.<br>
            미등록 상품 설정 : 카테고리에 속하지 않은 모든 상품들을 표시합니다.
          </p>
          <p class="mt-1">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">4 조회된 상품목록</span><br>
            3번에서의 선택된 조건에 따라 모든 상품들의 목록을 표시합니다.
          </p>
          <p class="mt-1">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">5 상품 제외하기</span><br>
            3번에서의 카테고리 상품 설정 선택시 표시됩니다. 선택된 상품들을 카테고리에서 제외 합니다.
          </p>
          <p class="mt-1">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">6 상품 추가하기</span><br>
            3번에서의 미등록 상품 설정 선택시 표시됩니다. 선택된 상품들을 카테고리에 추가 합니다.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
    data: () => ({
    }),

    computed: {

    },

    methods: {

    }
}
</script>
<style scoped>

</style>