<template>
  <div class="w-100 pb-10">
    <div style="border-bottom: 1px solid black" class="text-center py-8 w-100">
      <span class="text-h4">상품 등록</span>
    </div>
    <div class="mt-10 w-100">
      <div class="w-100 text-center">
        <span class="pl-15 text-h5 font-weight-bold">전시 기본정보</span>
      </div>
      <div class="text-center w-100 mt-5">
        <img src="../../assets/guide/product/product__reg_1.png" style="width: 900px" />
        <div style="width: 900px" class="mx-auto text-left pt-4">
          <p>
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">1 카테고리 선택</span><br>
            추가할 카테고리들의 체크박스에 체크를 하여 선택합니다. 하나 이상 선택이 가능합니다.
          </p>
          <img src="../../assets/guide/product/product__reg_2.png" style="width: 900px" />
          <p>
            선택된 카테고리 목록은 하기와 같이 표시 되며 노출 여부를 설정할 수 있습니다.<br>
            미체크시 카테고리에 속해 있으나 쇼핑몰에서 해당 카테고리 목록에서 보이지 않습니다.
          </p>
          <img src="../../assets/guide/product/product__reg_3.png" style="width: 900px" />
          <p class="mt-2">
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">2 상품 전시 여부</span><br>
            전시 : 상품이 쇼핑몰에 노출이 됩니다.<br>
            미전시 : 상품이 쇼핑몰에 노출이 되지 않습니다.
          </p>
          <p>
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">3 판매 기간 설정</span><br>
            카테고리 이름을 입력합니다.
          </p>
          <img src="../../assets/guide/product/product__reg_4.png" style="width: 600px" />
          <p>
            <span class="font-weight-bold px-2 py-1" style="color: black;background-color: #f6d0d0">4 판매 상태 설정</span><br>
            카테고리 이름을 입력합니다.
          </p>
          <img src="../../assets/guide/product/product__reg_5.png" style="width: 300px" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
    components:{

    },
    data: () => ({
        all:false,
        rules:false,
        cash:false,
        privacy:false,
        submitStatus: null
    }),

    computed: {

    },

    methods: {

    }
}
</script>
<style>
</style>