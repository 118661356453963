<template>
  <div style="width: 100%;" class="px-4 pt-8">
    <div>
      <span class="text-h5">홈</span>
    </div>
    <ul style="width: 100%;" class="mt-8">
      <li class="menu-list"
          v-bind:class="{ 'menu-active' : activeMenu('index-home')}"
          v-on:click="onSelected('index-home')">
        <span class="text-subtitle-1 menu-item"
              v-bind:class="{ 'menu-active' : activeMenu('index-home')}"
        >기본메뉴</span>
      </li>
    </ul>
  </div>
</template>
<script>

export default {
  components:{

  },
  created() {

  },
  data: () => ({
  }),

  computed: {
    guideMenuId(){
      return this.$store.getters.getGuideMenuId
    }
  },

  methods: {
    activeMenu(menuId){
      return this.guideMenuId === menuId
    },
    onSelected(menuId){
      this.$store.commit('setGuideMenuId',menuId)
      this.$router.push( {name: menuId})
          .catch(()=>{
            this.$router.go(this.$router.currentRoute)
          })
    }
  }
}
</script>
<style scoped>

</style>